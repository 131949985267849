import React from "react";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const particuliers = [
  { name: 'Déménagement particuliers', href: '/demenagement-particuliers/' },
  { name: 'Prestations', href: '/demenagement-particuliers/nos-prestations/' },
  { name: 'Boutique', href: '/boutique/' },
  { name: 'FAQ', href: '/demenagement-particuliers/faq/' },
]
export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-2 lg:px-4"
        aria-label="Global"
      >
        <a href="/" className="-m-1.5 p-1.5">
          <span className="sr-only">art Moval</span>
          <StaticImage
            src="../../images/Logo_ArtMoval.png"
            alt="Logo Art Moval Déménagement"
            height={60}
          />
        </a>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Ouvrir le menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6 ">

          <a href="/nous-connaitre/" className="text-base py-2 font-semibold leading-6 text-art-marron-fonce">Qui sommes nous</a>
          <Popover className="relative">
            <PopoverButton className="inline-flex items-center gap-x-1 text-base py-2 font-semibold leading-6 text-art-marron-fonce">
              <span>Particuliers</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </PopoverButton>

            <Transition
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                <div className="w-72 shrink rounded-xl bg-white p-4 text-lg font-semibold leading-6 text-art-marron-fonce shadow-lg ring-1 ring-gray-900/5">
                  {particuliers.map((item) => (
                    <a key={item.name} href={item.href} className="block p-2 hover:text-art-beige-fonce">
                      {item.name}
                    </a>
                  ))}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>

          <a href="/demenagement-entreprises/" className="text-base py-2 font-semibold leading-6 text-art-marron-fonce">Entreprises</a>
          <a href="/garde-meubles/" className="text-base py-2 font-semibold leading-6 text-art-marron-fonce">Garde-meubles</a>
          <a href="/contact/" className="text-base py-2 font-semibold leading-6 text-art-marron-fonce">Devis</a>







          <a
            href="tel:0472325757"
            className="inline-flex items-center justify-center px-2 py-2 border border-art-bleu rounded-md shadow-sm text-base font-medium text-art-marron-fonce bg-white hover:bg-art-bleu"
          >
            04 72 32 57 57
          </a>
          <a
            href="/contact/"
            className=" inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-art-beige bg-art-bleu hover:bg-art-bleu"
          >
            Contactez-nous
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-art-marron-fonce/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">art Moval</span>
              <StaticImage
                src="../../images/Logo_ArtMoval.png"
                alt="Logo Art Moval Déménagement"
                height={60}
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Fermer menu</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6 -mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-art-marron-fonce hover:bg-gray-50">



                <a href="/nous-connaitre/" className="py-2 block">
                  Qui sommes nous
                </a>

                <a href="/demenagement-particuliers/" className="py-2 block">
                  Déménagement de particuliers
                </a>

                <a href="/garde-meubles/" className="py-2 block pl-4">
                  Prestations
                </a>
                <a href="/garde-meubles/" className="py-2 block pl-4">
                  Boutique
                </a>
                <a href="/garde-meubles/" className="py-2 block pl-4">
                  FAQ
                </a>

                <a href="/demenagement-entreprises/">
                  Déménagement d’entreprises
                </a>

                <a href="/garde-meubles/" className="py-2 block">
                  Garde-meubles
                </a>
                <a href="/contact/" className="py-2 block">
                  Devis
                </a>





              </div>
              <div className="py-6">
                <a
                  href="/contact/"
                  className=" inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-art-beige bg-art-bleu hover:bg-art-bleu"
                >
                  Contactez-nous
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
